import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from 'gatsby';

let year = new Date();

const privacyPage = ({data}) => (
  <Layout env={data.site.siteMetadata.env}>
  <Navbar />
  <div className="container pt-120 pb-100">
    <div
      style={{
        background: '#0B71BC',
        color: '#e6e6e6',
        padding: '40px 0',
        marginBottom: '40px',
      }}
    >
      <div className="grid-wrapper" style={{ textAlign: 'center' }}>
        <div className="col-12">
          <h1 style={{ margin: '0', color: 'white' }}>Privacy Policy</h1>
        </div>
      </div>
    </div>
    <div className="grid-wrapper">
      <div className="col-12">
        <p>
        Welcome to BlueChat, a messaging platform for improved team-driven productivity (the “Platform” and the “Service”). The Platform operates through a cloud-based back-end and a client-side front-end available through your smartphone, desktop, laptop and web browser.
        </p>
        <p>This Privacy Policy (“Privacy Policy”) explains how information about you is collected and used by the Platform developed and operated by Blix, Inc. (“Blix” or “we”, “us”, “our”).</p>
        <p>We are committed to protecting and respecting data privacy. As explained below, we do not store your emails on our servers, and we do not sell your data. Please read this Privacy Policy carefully.</p>
        
        
        <p>This Privacy Policy contains the following information.</p>
        <div>
          <ol>
            <li>PERSONAL DATA PROCESSED</li>
            <li>HOW YOUR PERSONAL DATA IS PROCESSED</li>
            <li>COOKIES</li>
            <li>SECURITY AND DATA RETENTION</li>
            <li>INTERNATIONAL DATA TRANSFERS</li>
            <li>ADDITIONAL INFORMATION FOR INDIVIDUALS IN THE EU and UK</li>
            <li>ADDITIONAL INFORMATION FOR INDIVIDUALS IN CALIFORNIA</li>
            <li>GOOGLE API SERVICES - USAGE DISCLOSURE</li>
            <li>CHANGES TO THIS PRIVACY NOTICE</li>
          </ol>
        </div>
        <h3>1. PERSONAL DATA PROCESSED</h3>
        <h4>1.1 We process your email address. We also optionally process your name or nickname, photo and mobile phone number.</h4>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            We process your email address when you register to use BlueChat.
            </li>
            <li>
            You may also optionally provide the following items when you register: a chosen name, nickname or pseudonym to be displayed with your email account; a photo used as your avatar; and your mobile phone number for SMS verification.
            </li>
            <li>
            We also generate a unique app instance number when you install and run an instance of our client-side app on your smartphone, desktop, laptop and web browser. It is generated anew if you reinstall our app.  We do not use any hardware identifiers such as Unique Device Identifier (UDID), SSAID (Android ID), IDFA, GAID, or IMEI and we have no way to track the specific hardware you use.
            </li>
            <li>
            We refer to this as <strong>"Registration Information".</strong>
            </li>
          </ul>
        </div>

        <h4>1.2 We process you account preferences, such as background color, font and email signature.</h4>
         
            <p>
            We process your account settings, such as the background color you have chosen, the refresh rate and font settings you selected.
            </p>
            <p>
            We refer to this as <strong>"Account Settings Information".</strong>
            </p>


        <h4>1.3 We process billing information for organizations using BlueChat’s fee-based plans.</h4>

            <p>
            We may process payment information, such as credit card details, that we collect from the administrator of an organization’s use of BlueChat.
            </p>
            <p>
            We refer to this as <strong>"Billing Information".</strong>
            </p>


        <h4>1.4 When you use or access groups through the Platform, we process the group’s conversation setting which include the email addresses of the group members as well as other settings.</h4>
            <p>
            When you use or access groups through the Platform, we process the email addresses of the group members as well as the group’s selected name, avatar and notes.
            </p>
            <p>
            We refer to this as <strong>"Group Conversation Settings".</strong>
            </p>


        <h4>1.5 We will process user inquiries you choose to send us.</h4>

            <p>
            You can submit an inquiry through our online “Contact Us” form or by emailing or writing to us. We will collect the information you provide in the inquiry, which typically includes your name, email address, other contact information, country, and the text of your message. 
            </p>
            <p>
            We refer to this as <strong>"Inquiry Information".</strong>
            </p>


        <h4>1.6 We will process user feedbacks and reviews you provide.</h4>

          <ul style={{ listStyle: 'circle' }}>
            <p>
            You can provide us feedback, review, or answers to surveys or promotions, on social media channels, by posting on any of our online public forums or communities, by sending an e-mail to any of our designated addresses, or any other form of communication. We will process the data you choose to provide, which may include your name, email address and the feedback or review. 
            </p>
            <p>
            We refer to this as <strong>"Feedback Information".</strong>
            </p>
          </ul>

        <h4>1.7 In the provision of the Service, the Platform performs limited processing of you emails, chats, imail, tasks, remarks/comments, contacts and calendar events.</h4>

            <p>
            In order to provide the Service to you, the Platform is involved in processing your chats, remarks/comments, contactsbut in a very limited fashion as described below.            </p>
            <p>
            We refer to this as <strong>"Your Content".</strong>
            </p>
        <h4>You do not have a legal obligation to let us process any of the above information. However, you will not be able to use the Platform and the Service unless we process this information, because we require it to operate the Platform and provide you the Service.</h4>
        <h4>1.8 We collect and process measurements regarding your use of the Platform.</h4>

            <p>
            We collect meta-data such as the frequency of your use of the Platform, your interaction with the Platform’s user interface, general details such as the model of your smartphone and its operating system and information about bugs and crashes in our client-side app on your smartphone, desktop, laptop and web browser.             </p>
            <p>
            We refer to this as <strong>"Analytics Information".</strong>
            </p>
        <h3>2. HOW YOUR PERSONAL DATA IS PROCESSED</h3>
        <p><em>We will not share your information with third parties, except in the events listed below or when you provide us your explicit and informed consent.</em></p>
        
        <h4>2.1 We will process your information to provide you the functionality of the Service.</h4>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            We store and use your Registration Information, Account Settings Information, Credential Information and Group Conversation Settings in order to provide you the functionality of the Platform.            
            </li>
            <li>
            We use your Billing Information in order to charge the fees applicable to your organization’s use of the Platform. We do not store or maintain your credit card number and are not exposed to it. It is handled by payment processors who are certified to acceptable security standards when they handle this information.
            </li>
            <li>
            <strong>We do not store your email messages or calendar events on our servers. </strong>
            </li>
            <li>
            Our Platform’s servers store your chat messages. These are all stored in encrypted form, which you can read more about below.
            </li>
            <li>
            Our Platform’s servers also store your contacts, for the purpose of presenting to you their status as online or offline.
            </li>
          </ul>
        </div>

        <h4>2.2 Your name, avatar and online status will be visible to those participating in your email correspondence or imail chats.</h4>
        <p>

            The following information will be visible and available to those participating in your email correspondence or chats: the name, nickname or pseudonym you chose to be displayed with your email account, the photo you chose as your avatar, if any and your online or offline status.  

        </p>

        <h4>2.3 We will use the Inquiry Information to handle and respond to your inquiry for quality assurance and for development and enhancement of the Platform, to prevent fraud, resolve disputes, troubleshoot problems and related purposes.</h4>

            <p>
            We will use your Inquiry Information to contact you about your inquiry and handle and respond to your inquiry. If your inquiry is regarding any other information we have about you, we will process that other information to respond to your inquiry.
            </p>
            <p>
            We may also use the content of your inquiry to maintain and improve the Service, for quality assurance and for development and enhancement of the Platform, to prevent fraud, resolve disputes, troubleshoot problems, assist with any investigations and enforce our terms of use for the Platform.
            </p>


        <h4>2.4 We use the Analytics Information and your Feedback Information for our business needs of developing and enhancing the Service, prevent fraud, resolve disputes and troubleshoot problems and related purposes</h4>

            <p>
            We process the Analytics Information and Feedback Information to maintain and improve the Service, for quality assurance and for development and enhancement of the Platform.             </p>
            <p>
            We will also use the Analytics Information and Feedback Information to prevent fraud, resolve disputes, troubleshoot problems, assist with any investigations, and enforce our terms of use for the Platform.            </p>


        <h4>2.5 If you expressed your consent, we will use your email address to contact you with marketing messages, promotions and offers relating to the Platform and the Service.</h4>
        <p>

            We will use your email address to send you emails containing BlueChat updates, promotions, marketing, and special offers. We will only do so if you have given your consent. If you gave your consent but later wish to unsubscribe from these emails, you may opt-out of receiving them by following the unsubscribe link located at the bottom of each communication or by emailing us at <a style={{ color: '#1F6BF1' }} href="mailto:privacy@blix.net">privacy@blix.net.</a>

        </p>

        <h4>2.6 We will use your email address to contact you with administrative message relating to the Platform, the Service, this privacy policy and our terms of use.</h4>
        <p>

            We will use your email address to initiate contact with you on administrative issues related to the Services or support and maintenance.          

        </p>

        <h4>2.7 We will process information with our service providers helping us to operate our business.</h4>
        <p>

            We will process personal information with the assistance of our service providers who assist us with the internal operations of the Service. These companies. Which for example include Amazon Web Services and Twilio, are authorized to use your personal information in this context only as necessary to provide these services to us and not for their own business purposes.        

        </p>


        <h4>2.8 We will share information with competent authorities if you violate any applicable law.</h4>
        <p>

            If you violated any applicable law, we will share information we have about you with competent authorities and with third parties (such as legal counsels and advisors), for the purpose of handling of the violation.     

        </p>

        <h4>2.9 We will share your information if we are legally required.</h4>
        <p>
            We will share information we have about you if we are required to do so by a judicial, governmental or regulatory authority.       
        </p>

        <h4>2.10 We will share your information with third-parties in any event of change in our structure.</h4>
        <p>

            If the operation of our business is organized within a different framework, or through another legal structure or entity (such as due to a merger or acquisition), we will share information only as required to enable the structural change in the operation of the business.
        </p>
        <h3>3. COOKIES</h3>
        
        <h4>3.1 What are cookies?</h4>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            Cookies are text files, comprised of small amount of data, that are saved on your computer or other device (e.g., smartphone, tablet, etc.) when you use the internet and visit various websites. 
            </li>
            <li>
            The information that the cookies maintain is read by the website you visit, during the session of your visit to the website (these are called ‘session’ cookies), and when you return to visit it again (these are called ‘persistent’ cookies). We also use techniques called web beacons and web pixels for purposes similar to the use of cookies.
            </li>
          </ul>
        </div>

        <h4>3.2 We use cookies necessary to operate the Platform and for measurement of analytics.</h4>
        <p>We use cookies for a number of purposes, as briefly explained below:            </p>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            
            <li>
           <strong style={{fontWeight: 'bold'}}>Necessary.</strong>  We use a login cookie to validate your login credentials when you use login to the Platform through the web. This cookie is strictly necessary for the functioning of the Platform. The Platform cannot operate properly without this cookie.             </li>
            <li>
            <strong style={{fontWeight: 'bold'}}>Statistics. </strong> Analytics cookies that help us understand how you interact with the Platform by collecting data that does not directly identify you.
            </li>
          </ul>
        </div>

        <h4>3.3 You can always delete or disable cookies.</h4>
        <p>You can always delete the cookies saved on your device through the settings of your computer browser or device. You can also disable cookies for future use through the settings of your computer browser or device.           </p>
        

        <h3>4. SECURITY AND DATA RETENTION</h3>
        <h4>4.1 We will generally retain your information for as long as you are a registered user, and thereafter for recordkeeping purposes if necessary.</h4>
        <p>
        Generally, we will retain your information for as long as you are a registered user of the Platform. Thereafter, it will be either deleted or completely de-identified in a manner that does not allow re-identification thereof. Except as required by law or our agreement directly with you, we are not obliged to retain your information for any particular period, and we are free to securely delete it or restrict access to it for any reason and at any time, with or without notice to you.
        </p>
        <p>
        We will also retain and use your information in the following instances:
        </p>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            
            <li>
            Keep evidence to our compliance with applicable law (for instance, records of consents to our terms of use, privacy policy and other similar consents are kept for five years.            
            </li>
            <li>
            If there is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved.           
            </li>
            <li>
            The information must be kept for our legitimate business interests, such as fraud prevention and enhancing users’ safety and security. For example, information may need to be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account. 
            </li>
          </ul>
        </div>

        <h4>4.2 We implement measures to secure your information.</h4>
        <p>
        We implement measures to reduce the risks of damage, loss of information and unauthorized access or use of information. These include TLS and PGP encryption for data in transit and encryption of your chat messages when they are stored on our servers.
        </p>
        <p>
        In any event, these measures do not provide absolute information security. Therefore, although efforts are made to secure personal information, it is not guaranteed, and you cannot expect that the Service will be immune from information security risks.
        </p>


        <h3>5. INTERNATIONAL DATA TRANSFERS</h3>
        <h4>5.1 We will internationally transfer information in accordance with applicable data protection laws. </h4>
        <p>If we transfer your personal data for processing at locations outside your jurisdiction, we will abide by data transfer rules applicable to these situations.</p>

        <h3>6. ADDITIONAL INFORMATION FOR INDIVIDUALS IN THE EU and UK</h3>
        <h4>6.1 Blix is the data controller for all information other than Your Content.</h4>
        <p>
        Blix is the data controller for the following information: Registration Information, Account Settings Information, Billing Information, Group Conversation Settings, Credential Information, Inquiry Information, Feedback Information and Analytics Information.
        </p>
        <p>
        Blix is the data processor for Your Content.
        </p>
        <h4>6.2 Blix is the data processor for Your Content.</h4>
        <p>The following is the contact information of Blix:</p>

        <div>
          <ul>
            <li>
            Blix, Inc.           
             </li>
            <li>
            101 Hudson Street
            </li>
            <li>
            Jersey City, NJ 07302
            </li>
            <li>
            United States            
            </li>
            <li>
            <a style={{ color: '#1F6BF1' }} href="mailto:privacy@blix.net">privacy@blix.net</a>
            </li>
          </ul>
        </div>

        <h4>6.3 Legal bases under the GDPR for processing your personal data.</h4>
        <p>
        The legal basis under the GDPR for Blix’s processing of your Registration Information, Account Settings Information, Billing Information, Group Conversation Settings, Credential Information, as well as the use of the necessary-type cookie, is the performance of the terms of service contract in providing you the Platform the Service and its features.
        </p>
        <p>
        The legal basis under the GDPR for processing your Inquiry Information is our legitimate interest in handling and responding to your inquiry, developing, maintaining and improving our business, the Service and the Platform.
        </p>
        <p>
        The legal basis under the GDPR for processing your Analytics Information (including the use of the statistics cookie), and Feedback Information is our legitimate interest in developing, maintaining and improving our business, the Service and the Platform.
        </p>
        <p>
        The legal basis under the GDPR for processing your email address to contact you with administrative messages is our legitimate interest in keeping our users informed of updates relating to the Platform and our compliance with legal obligations of informing users where required by law.
        </p>
        <p>
        The legal basis under the GDPR for processing your email address to contact you with marketing-related emails is your express consent.
        </p>
        <p>
        The legal basis under the GDPR for sharing your information with authorities or where we are legally required to share it, is our legitimate interests in complying with mandatory legal requirements imposed on us.
        </p>
        <p>
        The legal basis under the GDPR for processing your information in the event of a change in our corporate structure is our legitimate interests in our business continuity.
        </p>


        <h4>6.4 You have certain rights to access, update or delete information, obtain a copy of your information, and object or restrict certain data processing activities.</h4>
        <p>
        If you are in the EU or the UK, you have the following rights under the GDPR:
        </p>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            <strong>Right to Access</strong> your personal data that we process, and receive a copy of it.        
             </li>
            <li>
            <strong>Right to Rectify</strong> inaccurate personal data we have concerning you and to have incomplete personal data completed.
            </li>
            <li>
            <strong>Right to withdraw</strong> your consent after you have given it to allow us to send you marketing-related emails.
            </li>
            <li>
            <strong>Right to Object</strong>, based on your particular situation, to using your personal data on the basis of our legitimate interest. However, we may override the objection if we demonstrate compelling legitimate grounds, or for the establishment, exercise of defense of legal claims.            
            </li>
            <li>
            <strong>Right to Data </strong>Portability, that is, to receive the personal data that you provided to us, in a structured, commonly used and machine-readable format. You have the right to transmit this data to another service provider. Where technically feasible, you have the right that your personal data be transmitted directly from us to the service provider you designate.
            </li>
            <li>
            <strong>Right to Restrict</strong> the processing your personal data (except for storing it) if you contest the accuracy of your personal data, for a period enabling us to verify its accuracy; if you believe that the processing is unlawful and you oppose the erasure of the personal data and request instead to restrict its use; if we no longer need the personal data for the purposes outlined in this Privacy Policy, but you require them to establish, exercise or defense relating to legal claims, or if you object to processing, pending the verification whether our legitimate grounds for processing override yours.
            </li>
            <li>
            <strong>Right to be Forgotten</strong>. Under certain circumstances, such as when you object to us processing your data and we have no compelling legitimate grounds to override your objection, you have the right to ask us to erase your personal data. However, we may still process your personal data if it is necessary to comply with a legal obligation, we are subject to under laws in EU Member States or for the establishment, exercise or defense of legal claims.
            </li>
          </ul>
        </div>

        <p>
        If you wish to exercise any of your EU rights, please contact us at: <a style={{ color: '#1F6BF1' }} href="mailto:privacy@blix.net">privacy@blix.net.</a>  
        </p>
        <p>
        We reserve the right to ask for reasonable evidence to verify your identity before we provide you with information. Where we are not able to provide you the information that you have asked for, we will explain the reason for this.
        </p>

        <h4>6.5 You have a right to submit a complaint to the relevant supervisory data protection authority.</h4>
        <p>Subject to applicable law, you have the right to lodge a complaint with your local data protection authority. If you are in the EU of UK, then according to the GDPR, you can lodge a complaint to the supervisory authority, in particular in the Member State of your residence, place of work or place of alleged infringement of the GDPR. For a list of supervisory authorities in the EU and UK, click here.</p>


        <h3>7. ADDITIONAL INFORMATION FOR INDIVIDUALS IN CALIFORNIA</h3>
       <p>Below is a detailed description of the information we collect from users to our commercial purposes for which we use each category of personal information. This is also the information we have collected in the past 12 months.</p>

       <h4>7.1 Categories of personal information and source from which the information is collected</h4>
       <p>Identifiers such as a real name, alias, online identifier, internet protocol address, email address and account name – collected directly from you.</p> 
       <p>Commercial information about the Service subscriptions purchased– collected directly from you if you are the administrator of an organization’s use of BlueChat</p> 
       <p>Internet or other electronic network activity information – collected through the device you use to access the Service.</p> 

       <h4>7.2 Specific Types of Personal Information Collected</h4>
       <p>As described above in the “Personal Data Processed” section.</p>
       <h4>7.3 Business or commercial purposes pursuant to the CCPA</h4>
       <p>Undertaking activities to verify or maintain the quality of the Service and to improve, upgrade or enhance the Service.</p>
       <p>Undertaking internal research for technological development and demonstration.</p>
       <p>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</p>
       <p>Debugging to identify and repair errors.</p>

       <h4>7.4 Specific purposes</h4>
       <p>As specified above in the “How Your Personal Data is Processed” section.</p>

       <h3><strong>We do not sell your personal information and have not done so in the past 12 months.</strong></h3>
       <h3><strong>Your rights under the CCPA if you are a resident of California.</strong></h3>

        <h4>7.5 Disclosure of personal information we collect about you. </h4>
        <p>You have the right to know: </p>

        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            The categories of personal information we have collected about you;         
             </li>
            <li>
            The categories of sources from which the personal information is collected; 
            </li>
            <li>
            Our business or commercial purpose for collecting personal information; 
            </li>
            <li>
            The categories of third parties with whom we share personal information, if any; and           
              </li>
            <li>
            The specific pieces of personal information we have collected about you.           
             </li>
          </ul>
        </div>

        <h4>7.6 Right to deletion. </h4>
        <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            Delete your personal information from our records; and             </li>
            <li>
            Direct any service providers to delete your personal information from their records.            </li>
          </ul>
        </div>

        <p>Please note that we may not delete your personal information if it is necessary to:</p>
        <div>
          <ul style={{ listStyle: 'circle' }}>
            <li>
            Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;            </li>
            <li>
            Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;           </li>
            <li>Debug to identify and repair errors that impair existing intended functionality;</li>
            <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
            <li>Comply with the California Electronic Communications Privacy Act;</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
            <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
            <li>Comply with an existing legal obligation; or</li>
            <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
          </ul>
        </div>

        <h4>7.7 Protection against discrimination.</h4>
        <p>You have the right to not be discriminated against by us because you exercised any of your rights under the CCPA. This means we cannot, among other things:</p>

        <div>
          <ul style={{ listStyle: 'circle' }}>

            <li>Deny goods or services to you;</li>
            <li>Charge different prices or rates for goods or services, including through the use of discounts or other benefits or imposing penalties;</li>
            <li>Provide a different level or quality of goods or services to you; or</li>
            <li>Suggest that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
          </ul>
        </div>

        <p>Please note that we may charge a different price or rate or provide a different level or quality of goods and/or services to you, if that difference is reasonably related to the value provided to our business by your personal information.</p>

        <h4>7.8 Exercising your CCPA rights.</h4>
        <p>If you would like to exercise any of your CCPA rights as described in this Privacy Policy, please email us at: <a style={{ color: '#1F6BF1' }} href="mailto:privacy@blix.net">privacy@blix.net.</a> </p>
        <p>We may ask you for additional information to confirm your identity and for security purposes, before disclosing the personal data requested to you, by using a two or three points of data verification process, depending on the type of information you require. </p>

        <h3>8. Google API Services - Usage Disclosure</h3>
        <p>BlueChat's use and transfer to any other app of information received from Google Accounts will adhere to Google API Services User Data Policy, including the Limited Use requirements.</p>
        
        <h3>9. CHANGES TO THIS PRIVACY NOTICE</h3>
        <h4>If we change this Privacy Policy, we will make efforts to proactively notify you of such changes.</h4>
        <p>From time to time, we may change this Privacy Policy. If we do so, we will make efforts to proactively notify you of such changes. In any event, the latest version of the Privacy Policy will be accessible at https://bluemail.me/chat/privacy/</p>
        

        

        <hr />

        <p>Last Modified: January 31, 2021</p>
        <p>
          <a style={{ color: '#1F6BF1' }} href="https://blix.net">
            © {year.getFullYear()} Blix Inc.
          </a>
        </p>
      </div>
    </div>
  </div>
  <Footer />
  </Layout>
)

export default privacyPage

export const query = graphql`
query BluechatPrivacyPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
